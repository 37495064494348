import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useQuery } from 'react-query'
import { axiosPostCall, axiosGetCall } from '../../services/apisCall';
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';

//mui
import { Box, Button, TableCell, List, ListItem, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, TableContainer, Table, TableHead, TableRow, Popover, Paper, TableBody } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import ChecklistTwoToneIcon from '@mui/icons-material/ChecklistTwoTone';

// component
import { CustomMenu } from '../../components/menu/customMenu';


//sections
import TableFilters from './tableFilter';

// config
import { USER_KYC_LIST_REFETCH_TIME } from '../../config/commonConfig';

//logger
import Logger from '../../lib/Logger';
import { PaginationTable } from './paginationTable';
import CustomTabPanel from './customTabPanel';
import { checkButtonAccess } from 'src/utils/common';
const logger = new Logger("kycListTable");

const announcementColumns = [
    { id: 'user_id', label: 'User ID', minWidth: 100, align: 'center' },
    { id: 'ann_id', label: 'Ann Id', minWidth: 100, align: 'center' },
    { id: 'title', label: 'Title', minWidth: 110, align: 'center' },

    { id: 'create_date', label: 'Date & Time', minWidth: 200, align: 'center' },
    { id: 'announcementUrl', label: 'Audio', minWidth: 110, align: 'center' },
    { id: 'route', label: 'Route', minWidth: 110, align: 'center' },
    { id: 'actions', label: 'Action', minWidth: 110, align: 'center' },
];

const ivrColumns = [
    { id: 'user_id', label: 'User ID', minWidth: 110, align: 'center' },
    { id: 'ivr_id', label: 'Ivr Id', minWidth: 110, align: 'center' },
    { id: 'ivr_title', label: 'Title', minWidth: 110, align: 'center' },

    // { id: 'create_by_type', label: 'User Type', minWidth: 110, align: 'center' },
    { id: 'ivr_type', label: 'Ivr Type', minWidth: 110, align: 'center' },
    { id: 'used_module', label: 'Used Module', minWidth: 150, align: 'center' },
    { id: 'ttsContent', label: 'Tts Content', minWidth: 110, align: 'center' },
    { id: 'create_date', label: 'Date & Time', minWidth: 110, align: 'center' },
    { id: 'route', label: 'Route', minWidth: 110, align: 'center' },
    { id: 'actions', label: 'Action', minWidth: 110, align: 'center' },
];

export const AnnouncementListTable = ({ type }) => {

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [rows, setRows] = useState([])
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState("")
    const [load, setLoad] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [selectedStatus, setSelectedStatus] = useState("P")
    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [selectedUserId, setSelectedUserId] = useState("")
    const [confirmModal, setConfirmModal] = useState(false)
    const [alreadyApproved, setAlreadyApproved] = useState(false)
    const [userKYCStatus, setUserKYCStatus] = useState("")

    const handleVerifyClick = (userId) => {
        setSelectedUserId(userId)
        setOpenModal(true)
        setAnchorEl(false)
    }
    const handleClick = (event, userId) => {
        setAnchorEl(event.currentTarget);
        setSelectedUserId(userId)
    };
    const handleModalClose = () => {
        setOpenModal(false);
        setLoad(true)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (value) => setSearch(value)

    const getAnnouncementList = async () => {
        const skip = rowsPerPage * page;
        try {
            const params = { skip, limit: rowsPerPage }
            if (search) params.search = search
            if (selectedStatus) params.status = selectedStatus
            setNetworkError(false)


            const data = await axiosGetCall(type === "announcement" ? "fetchAnnouncementList" : "fetchIvrList", params)
            setLoading(false);
            return data

        } catch (error) {
            logger.error("KycListTable.jsx ,getAnnouncementList ,getAnnouncementList", error);
            setNetworkError(true)
            setRows([])
            setCount(0)
            renderErrorMessage(error?.message)
        }
        setLoading(false)
    }

    const { data, refetch } = useQuery({ queryKey: ['getAnnouncementList', selectedStatus, page, rowsPerPage], queryFn: getAnnouncementList, refetchOnWindowFocus: false, retry: false, refetchInterval: USER_KYC_LIST_REFETCH_TIME })
    useEffect(() => {
        if (type === "announcement") {

            setRows(data?.announcementList || [])
            setCount(data?.totalAnnouncementCount || 0)
        }
        else {
            setRows(data?.documents || [])
            setCount(data?.totalCount || 0)
        }
    }, [data])

    useEffect(() => {
        if (load) {
            setLoading(true)
            refetch()
        }
        setLoad(false)
    }, [load])

    useEffect(() => {
        refetch();
    }, [type])

    const tableFilter = <TableFilters search={search} handleChange={handleChange} setLoad={setLoad} type={type} />

    const updateAnnouncementStatus = async (row, status) => {
        try {

            const { message } = await axiosPostCall("updateAnnouncementStatus", { annId: row?.ann_id, status })
            renderSuccessMessage(message)
            refetch();

        } catch (error) {
            logger.error("erorr in update status")
        }
    }

    const updateIvrStatus = async (row, status) => {
        try {

            const { message } = await axiosPostCall("updateIvrStatus", { ivrId: row?.ivr_id, status })
            renderSuccessMessage(message)
            refetch();


        } catch (error) {
            logger.error("erorr in update status")
        }
    }

    const ActionJsx = ({ id, align, value, row }) => {
        return (
            <TableCell
                key={id}
                align={align}
                sx={{
                    color: 'grey.400',
                    borderBottom: '0.5px dashed',
                    borderColor: 'grey.300',
                    size: 'small'
                }}>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px"
                    }}
                >
                    {checkButtonAccess("updateAnnouncementStatus", "w") && <>
                        {row?.status !== 1 && <Button variant='contained' color='success' onClick={() => type === "announcement" ? updateAnnouncementStatus(row, "A") : updateIvrStatus(row, "A")}>Approved</Button>}
                        {row?.status !== 2 && <Button variant='contained' color='error' onClick={() => type === "announcement" ? updateAnnouncementStatus(row, "R") : updateIvrStatus(row, "R")}>Reject</Button>}
                    </>}
                </Box>
            </TableCell>
        )
    }

    const contentStyle = { height: '20px', width: '20px', marginRight: '5px' }

    const Confirmation = () => (
        <Dialog open={confirmModal} PaperProps={{
            sx: {
                width: "370px",
                maxHeight: "180px"
            }
        }}>
            <DialogTitle >Partial Verify</DialogTitle>
            <DialogContent sx={{ marginTop: '-13px' }}>
                {alreadyApproved ? (<Typography> Already approved! would you like to <span style={{ fontWeight: 'bold' }}>{'Reset'}</span>?</Typography>)
                    : (<Typography>Are you sure you want to <span style={{ fontWeight: 'bold' }}>{'Partially update'}</span>?</Typography>)
                }          </DialogContent>
            <DialogActions sx={{
                marginTop: "-6px",
                marginBottom: "5px",
            }}>
                {alreadyApproved ? <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                        color: '#fff',
                        height: '30px',
                        backgroundColor: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'primary.main',
                        },
                        boxShadow: "none",
                        marginRight: '10px'
                    }}
                    onClick={() => update(selectedUserId, { flag: 1 })}
                >
                    Reset
                </Button> : <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                        color: '#fff',
                        height: '30px',
                        backgroundColor: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'primary.main',
                        },
                        boxShadow: "none",
                        marginRight: '10px'
                    }}
                    onClick={() => update(selectedUserId)}
                >
                    Update
                </Button>}
                <Button
                    variant="outlined"
                    sx={{
                        height: '30px',
                        borderColor: 'red',
                        color: 'red',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 0, 0, 0.04)',
                            borderColor: 'red',
                        }
                    }}
                    onClick={() => updateCancel()}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );

    const TtsContentJsx = ({ id, align, value, row }) => {
        const [anchorEltts, setAnchorEltts] = React.useState(null);

        const handleClicktts = (event) => {
            setAnchorEltts(event.currentTarget);
        };

        const handleClosetts = () => {
            setAnchorEltts(null);
        };

        const opentts = Boolean(anchorEltts);
        const Id = opentts ? 'simple-popover' : undefined;

        return <TableCell
            key={id}
            align={align}
            sx={{
                color: 'grey.700',
                borderBottom: '0.5px dashed',
                borderColor: 'grey.300',
            }}>
            {Object.keys(value || {}).length > 0 ? <div>
                <Button aria-describedby={Id} variant="contained" onClick={handleClicktts} color='success'>
                    Content
                </Button>
                <Popover
                    id={Id}
                    open={opentts}
                    anchorEl={anchorEltts}
                    onClose={handleClosetts}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 200 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>

                                    <TableCell align="right">Node</TableCell>
                                    <TableCell align="right">Content</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(value).map((key) => (
                                    <TableRow
                                        key={key}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {key}
                                        </TableCell>
                                        <TableCell align="right">{value[key]}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Popover>
            </div> : "--"}
        </TableCell>
    }

    return (
        <>
            <ToastContainer />
            <Confirmation />
            <CustomTabPanel setSelectedStatus={setSelectedStatus} setLoading={setLoading} setPage={setPage} />
            <PaginationTable
                page={page}
                tableFilter={tableFilter}
                ActionJsx={ActionJsx}
                // ActionModals={ActionModals}
                setLoad={setLoad}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={count}
                error={networkError}
                loading={loading}
                setLoading={setLoading}
                rows={rows}
                columns={type === "announcement" ? announcementColumns : ivrColumns}
                handleChange={handleChange}
                search={search}
                calculatedHeight={377}
                TtsContentJsx={TtsContentJsx}
            />
        </>
    )
}