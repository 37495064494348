import { Route, Outlet } from "react-router-dom";
import KYC from "../pages/kyc";
import Navbar from "../layouts/Navbar";
import Home from "../pages/home";
import { ProtectedRoute, PublicRoute } from "./hoc";
import ConsoleUser from "../pages/consoleUser";
import Profile from "../pages/profile";
import Sidebar from "../layouts/Sidebar";
import { checkRouteAccess } from "../utils/common";
import Login from "../pages/login";
import Users from "../pages/users";
import Wave from "../pages/wave";
import AddNewUser from "../pages/addNewUser";
import Advertisement from "../pages/advertisement";
import NotFound from "../pages/notFound";
import PermissionDenied from "../pages/permissionDenied";
import Leads from "../pages/leads";
import LeadsAnalysis from "src/pages/leadsAnalysis";
import UpdatePanelPermission from "src/pages/updatePermission";
import NewUsers from "src/pages/createConsoleUser";
import AnnouncementList from "src/pages/announcementList";

const AllRoutes = () => {

    return (
        <>
            <Route element={<><ProtectedRoute><Sidebar /><Navbar />  <Outlet /> </ProtectedRoute></>}>
                {checkRouteAccess("/kyc") && <Route exact path="/kyc" element={<KYC />} />}
                {checkRouteAccess("/console_users") && <Route exact path="/console_users" element={<ConsoleUser />} />}
                {checkRouteAccess("/users") && <Route exact path="/users" element={<Users />} />}
                {checkRouteAccess("/profile") && <Route exact path="/profile" element={<Profile />} />}
                {checkRouteAccess("/home") && <Route exact path='/home' element={<Home />} />}
                {checkRouteAccess("/meet") && <Route exact path='/meet' element={<Wave />} />}
                {checkRouteAccess("/webinar") && <Route exact path='/webinar' element={<Wave />} />}
                {checkRouteAccess("/sms") && <Route exact path='/sms' element={<Wave />} />}
                {checkRouteAccess("/add_new_user") && <Route exact path='/add_new_user' element={<AddNewUser />} />}
                {checkRouteAccess("/advertisement") && <Route exact path='/advertisement' element={<Advertisement />} />}
                {checkRouteAccess("/leads") && <Route exact path='/leads' element={<Leads />} />}
                {checkRouteAccess("/leads") && <Route exact path='/leads_analysis' element={<LeadsAnalysis />} />}
                {checkRouteAccess("/updatepermissions") && <Route exact path='/updatepermissions' element={<UpdatePanelPermission />} />}
                {checkRouteAccess("/createUser") && <Route exact path="/createUser" element={<NewUsers />} />}
                {checkRouteAccess("/createUser") && <Route exact path="/updateUser" element={<NewUsers />} />}
                {checkRouteAccess("/announcementList") && <Route exact path="/announcementList" element={<AnnouncementList type="announcement" />} />}
                {checkRouteAccess("/ivrList") && <Route exact path="/ivrList" element={<AnnouncementList page="ivr" />} />}

                {/* <Route exact path="/updateUser" element={<NewUsers />} /> */}
            </Route>

            <Route element={<><PublicRoute ><Outlet /></PublicRoute> </>}>
                <Route exact path="/" element={<Login />} />
            </Route>

            <Route exact path='/permisson_denied' element={<PermissionDenied />} />
            <Route exact path='*' element={<NotFound />} />
        </>
    )
}

export default AllRoutes
