import { TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const SearchTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    borderColor: theme.palette.grey[700],
    borderRadius: '6px',
    '& input': {
        color: theme.palette.grey[500],
    },
    width: '100%',
}));

const SearchButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
    }
}))

const MainSection = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
})

const TableFilters = ({ search, handleChange, setLoad, type }) => {
    return (
        <>
            <MainSection>
                <SearchTextField
                    type="number"
                    id="searchUserId"
                    onChange={(e) => handleChange(e.target.value)}
                    value={search}
                    size='small'
                    placeholder= {type==="announcement"?"Search by Ann Id":"Search by Ivr Id"}
                    variant="outlined"
                />
                <SearchButton
                    variant='contained'
                    size='medium'
                    onClick={() => setLoad(true)}
                >
                    Search
                </SearchButton>
            </MainSection>
        </>
    )
}

export default TableFilters;