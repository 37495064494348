import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage.json';
import { getBulkItems, getItem, removeItem, setBulkItems } from '../lib/cookies';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../hooks/useAuth';
import { NON_SECURE_PATH } from '../config/commonConfig';

//For handle apis error response message.
const prepareMessageFromError = (error) => {
    return error?.response?.data?.message || error?.message || ErrorMessage?.[4000]
}

//Define prop type of fn.
prepareMessageFromError.propTypes = {
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]), // pass complete error or custom message.
}

// Set user session.
const setLoginSession = (exp, sessionToken, token) => {
    return setBulkItems([
        { key: 'exp', value: exp },
        { key: "sessionToken", value: sessionToken },
        { key: "token", value: token },
    ]);
}

const removeLoginSession = () => {
    removeItem("exp");
    removeItem("sessionToken");
    removeItem("token");
}

setLoginSession.propTypes = {
    exp: PropTypes.number.isRequired,
    sessionToken: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
}

// Get user session.
const getLoginSession = () => {
    const cookiesNameArray = ["exp", "sessionToken", "token"];
    return getBulkItems(cookiesNameArray)
}

// convert date to ts
const dateToTs = (date) => {
    const dateArray = date.split("-");
    const tsDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
    return tsDate.getTime()
}

const checkRouteAccess = (path) => {

    try {
        const auth = useAuth();
        if (!auth) {
            removeLoginSession();
            return false;
        }

        const { access } = jwtDecode(getItem("token"));

        if (NON_SECURE_PATH.includes(path)) return true;

        const accessPathMapper = {

            "/kyc": access?.[1001]?.r,
            "/console": access?.[3001]?.r || access?.[4001]?.r,
            "/console_users": access?.[3001]?.r,
            "/users": access?.[2001]?.r && checkUserRole() !== "agent",
            "/add_new_user": access?.[2002]?.w,
            "/advertisement": access?.[4001]?.r,
            "/meet": access?.[5001]?.r,
            "/webinar": access?.[6001]?.r,
            "/sms": access?.[7001]?.r,
            "/home": access?.[8001]?.r,
            "/leads": access?.[2003]?.r,
            "/updatepermissions": checkUserRole() === "superAdmin",
            "/createUser": access?.[3006]?.r,
            "/announcementList": access?.[9001]?.r,
            "/ivrList": access?.[9001]?.r,
        }
        if (Object.keys(accessPathMapper).includes(path)) {
            return accessPathMapper[path]
        }
        return false
    } catch (error) {
        console.log(error);
    }
}

const checkButtonAccess = (path, accessType) => {
    const auth = useAuth();
    if (!auth) return auth;
    const { access } = jwtDecode(getItem("token"));
    const accessPathMapper = {
        "addNewUser": access?.[2002],
        "verifyKyc": access?.[1003],
        "refLogin": access?.[3002],
        "forceVerify": access?.[3003],
        "updateConsoleAdmin": access?.[3004],
        "viewUserPassword": access?.[3005],
        "createConsoleUser": access?.[3006],
        "updateAnnouncementStatus": access?.[9002],

    }
    if (Object.keys(accessPathMapper).includes(path)) {
        return accessPathMapper?.[path]?.[accessType];
    }
    return false;
}

const checkUserRole = () => {
    const auth = useAuth();
    if (!auth) return auth;
    const { role } = jwtDecode(getItem("token"));
    return role
}

const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);
}

// formate name like first letter upper case.
const nameFormater = (value) => {
    return value && value[0].toUpperCase() + value.slice(1).toLowerCase();
}

const compareObjectValues = (prev, updated) => {

    for (let [key] of Object.entries(updated)) {
        if (updated?.[key] && prev[key] !== updated[key]) {
            return true;
        }
    }
    return false;
}

// For random password purpose
function generateRandomString() {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const digits = '0123456789';
    const specialChars = '!@#$%^&*()_+[]{}|?';

    const allChars = lowercase + uppercase + digits + specialChars;
    const length = Math.floor(Math.random() * 13) + 8; // Length between 8 and 20

    let result = '';

    // Ensure the result has at least one of each required character
    result += lowercase[Math.floor(Math.random() * lowercase.length)];
    result += uppercase[Math.floor(Math.random() * uppercase.length)];
    result += digits[Math.floor(Math.random() * digits.length)];
    result += specialChars[Math.floor(Math.random() * specialChars.length)];

    // Fill the rest of the string with random characters
    for (let i = result.length; i < length; i++) {
        result += allChars[Math.floor(Math.random() * allChars.length)];
    }

    // Shuffle the result to ensure randomness
    result = result.split('').sort(() => 0.5 - Math.random()).join('');

    return result;
}

export {
    prepareMessageFromError,
    setLoginSession,
    getLoginSession,
    dateToTs,
    checkRouteAccess,
    checkButtonAccess,
    checkUserRole,
    removeLoginSession,
    copyToClipBoard,
    nameFormater,
    compareObjectValues,
    generateRandomString
}