import React from 'react';
import moment from 'moment';
import propTypes from 'prop-types'
import commonMessage from '../../utils/commonMessage.json'

//mui material
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Chip, Box, Typography, Icon, IconButton, Button, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';

// mui icons
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GppBadIcon from '@mui/icons-material/GppBad';
import HistoryIcon from '@mui/icons-material/History';
import VerifiedIcon from '@mui/icons-material/Verified';

//components
import NetworkError from 'src/components/Error/networkError';
import { CustomSkeleton } from '../../sections/skeleton/skeletonLoader';
import Tooltip from '@mui/material/Tooltip';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { copyToClipBoard, nameFormater } from 'src/utils/common';
import { renderErrorMessage, renderSuccessMessage } from 'src/lib/toastMessage';
import { LoadingButton } from '@mui/lab';
import iconify from 'src/components/iconify';
import { axiosGetCall } from 'src/services/apisCall';
import CircularProgressWithLabel from 'src/components/tables/circleProgressBar';
import { Announcement } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
// import gLogin from './gLogo.png';

// Show table filtes.
const TableFilterSection = styled('div')(() => ({
  width: 'auto',
  padding: '16px 16px 16px 16px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));

export const PaginationTable = ({
  TtsContentJsx,
  page = 0,
  tableFilter,
  ActionJsx,
  ActionModals,
  setPage,
  rowsPerPage = 10,
  setRowsPerPage,
  setLoad,
  count = 0,
  error = false,
  loading,
  setLoading,
  rows,
  columns,
  size = "small",
  calculatedHeight = 0
}) => {

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
    setLoad(true)
    setLoading(true)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoad(true)
    setLoading(true)
  };

  const dateFormatJsx = (id, align, value) => {
    const date = moment(value * 1000);
    const formattedDate = date.format('D MMM, YYYY');

    return (
      <TableCell
        key={id}
        align={align}
        sx={{
          color: 'grey.700',
          borderBottom: '0.5px dashed',
          borderColor: 'grey.300',
        }}>
        <>
          <Box>{formattedDate}</Box>

        </>
      </TableCell>)
  }

  const roleFormatJsx = (id, align, value) => {
    const roleFormatMapper = {
      superAdmin: "Super-Admin",
      admin: "Admin",
      agent: "Agent"
    }

    return (
      <TableCell
        key={id}
        align={align}
        sx={{
          color: 'grey.700',
          borderBottom: '0.5px dashed',
          borderColor: 'grey.300',
        }}>
        {roleFormatMapper[value]}
      </TableCell>
    )

  }

  const defaultRowJsx = (id, align, value) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
      }}>
      {value || "--"}
    </TableCell>
  )

  const userTypeJsx = (id, align, value) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
      }}>
      {nameFormater(value || "--")}
    </TableCell>
  )

  const audioPlayerJsx = (id, align, value) => {

    return <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
      }}>
      <audio controls src={value} style={{ height: 38 }} >
      </audio>
    </TableCell>
  }

  const routeJsx = (id, align, value) => {

    const color = {
      transactional: "success",
      promotional: "warning"
    }

    const label = {
      transactional: "Transactional",
      promotional: "Promotional"
    }

    return <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
      }}>
      {value ? <Chip label={label[value]} color={color[value]} /> : "--"}
    </TableCell>

  }

  const usedModuleJsx = (id, align, value, row) => {
    return <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
      }}>
      <Tooltip title={value}>
        <IconButton>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
  }



  const actionJsx = (id, align, value, row) => {
    return <ActionJsx key={id} id={id} align={align} value={value} row={row} />
  }

  const ttsContentJsx = (id, align, value, row) => {
    return <TtsContentJsx key={id} id={id} align={align} value={value} row={row} />
  }

  const tableRowData = {
    create_date: dateFormatJsx,
    role: roleFormatJsx,

    userType: userTypeJsx,

    announcementUrl: audioPlayerJsx,
    actions: actionJsx,
    route: routeJsx,
    used_module: usedModuleJsx,
    ttsContent: ttsContentJsx
  }

  return (
    <Paper sx={{
      overflow: 'hidden',
      backgroundColor: 'grey.1000',
      borderRadius: '6px'
    }}>

      <TableFilterSection>
        {tableFilter}
      </TableFilterSection>

      <TableContainer sx={{ maxHeight: window.innerHeight - calculatedHeight }}>
        <Table stickyHeader aria-label="sticky table" size={size}>

          <TableHead sx={{
            position: "sticky"
          }} >
            <TableRow >
              {columns.map(({ id, align, minWidth, label }) => (
                <TableCell
                  key={id}
                  align={align}
                  style={{ minWidth }}
                  sx={{
                    backgroundColor: 'grey.200',
                    color: 'grey.600',
                  }}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {!loading ?
            <TableBody>
              {rows.map((row) =>
              (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row?.userId || row?.email}
                >
                  {columns.map(({ id, align }) => (tableRowData?.[id] || defaultRowJsx)(id, align, row[id], row))}
                </TableRow>
              )
              )}
            </TableBody>
            :
            <TableBody>
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
              >
                {columns.map(({ id, align }) => (
                  <TableCell
                    key={id}
                    align={align}
                    sx={{
                      color: 'grey.300',
                      borderBottom: '1px solid',
                      borderColor: 'grey.800',
                    }}
                  >
                    <CustomSkeleton variant={"text"} component={"h6"} width={"100%"} />
                  </TableCell>
                ))}
              </TableRow>

            </TableBody>
          }
        </Table>
      </TableContainer>

      {count > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            backgroundColor: 'grey.1000',
            color: 'grey.700',
          }}
        />
      )}

      {!(count || loading) &&
        < Box sx={{
          height: {
            xs: "480px",
            sm: "480px",
            md: "480px",
            lg: "480px",
            xl: "670px",
          },
          display: 'flex',
          justifyContent: 'center'
        }}>
          <NetworkError message={!error ? commonMessage?.noDataFound : commonMessage?.networkError} calculatedHeight={calculatedHeight} />
        </Box>
      }
      {ActionModals && <ActionModals />}
    </Paper>
  )
}



PaginationTable.propTypes = {
  page: propTypes.number,
  setPage: propTypes.func.isRequired,
  rowsPerPage: propTypes.number,
  setRowsPerPage: propTypes.func.isRequired,
  setLoad: propTypes.func.isRequired,
  count: propTypes.number,
  error: propTypes.bool,
  loading: propTypes.bool,
  setLoading: propTypes.func,
  rows: propTypes.array.isRequired,
  columns: propTypes.array.isRequired,
  maxHeight: propTypes.number,
}
